import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import ArticleCard from '../components/ArticleCard';

const IndexPage = ({ data }) => {
	return (
		<Layout>
			<Seo />
			<div className="o-wrapper">
				<div className="o-row-3 o-row-6-d">
					<div className="c-index-hero">
						<h1 className="c-index-hero__title">
							<mark>Beach Wheelers</mark>
							<br />
							<mark className="c-index-hero__title-sm">Everything Beach Wagons</mark>
						</h1>
						<GatsbyImage image={getImage(data.file.childImageSharp)} alt="beach cart in the sand" />
					</div>
				</div>
				<div className="o-row-3 o-row-6-d">
					<p className="c-md-p">
						If you're looking forward to spending a day at the beach using a beach wagon or cart can make your day
						lighter. If you are unfamiliar with beach wagons you've come to the right place. Read our 2022 top rated
						beach wagon reviews. See our top 10s ranked based on usage, fishing wagons, family wagons, and party wagons.
					</p>
				</div>
				<div className="o-row-3 o-row-6-d">
					<h2 className="c-md-h2">Latest Post</h2>
				</div>
				<div className="o-row-3 o-row-6-d">
					<div className="c-article-card-wrapper">
						{data.allMdx.nodes.map((post, i) => (
							<ArticleCard
								key={i}
								url={post.slug}
								img={post.frontmatter.featuredImage}
								title={post.frontmatter.headline}
								description={post.excerpt}
							/>
						))}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default IndexPage;

export const pageQuery = graphql`
	query {
		file(name: { eq: "background-jumbo" }) {
			childImageSharp {
				gatsbyImageData(width: 890, placeholder: BLURRED, formats: AUTO)
			}
		}
		allMdx(limit: 4, sort: { fields: frontmatter___date, order: DESC }) {
			nodes {
				excerpt(pruneLength: 400)
				frontmatter {
					featuredImage {
						childImageSharp {
							gatsbyImageData(width: 240, placeholder: BLURRED, formats: AUTO)
						}
					}
					headline
				}
				slug
			}
		}
	}
`;
